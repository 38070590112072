import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useSearchParam } from "react-use";
import { navigate } from "gatsby";

import { LayoutWrapper, Input, Select, Checkbox } from "../components";
import { resetPwd } from "../apis";

const Reset = () => {
  const { register, handleSubmit, watch, formState, getValues, setValue } =
    useForm();
  const mutation = useMutation(({ regionDomain, ...resetForm }) =>
    resetPwd(resetForm, regionDomain)
  );
  const watchPwd = watch(["password", "confirmedPassword"]);
  const email = useSearchParam("email");
  const otp = useSearchParam("otp");
  const region = useSearchParam("region");

  const onSubmit = async (data) => {
    if (mutation.isLoading) return;
    const { password, ...rest } = data;
    if (email && otp) {
      try {
        const res = await mutation.mutateAsync({
          password,
          email,
          otp,
          regionDomain: region,
        });
        navigate("/reset-success");
      } catch (error) {
        console.log(error.response);
      }
    }
  };
  return (
    <LayoutWrapper title={"Reset password"}>
      <Input
        label="New Password"
        placeholder="New Password"
        {...register("password", {
          required: {
            value: true,
            message: "this field is required",
          },
        })}
        type="password"
        typeToogle={watchPwd[0]?.length > 0}
        error={formState.errors?.password}
      ></Input>
      <Input
        label="Confirm New Password"
        placeholder="Confirm New Password"
        {...register("confirmedPassword", {
          required: {
            value: true,
            message: "this field is required",
          },
          validate: {
            isConsist: (value) =>
              value === watchPwd[0] || "password is not consist",
          },
        })}
        type="password"
        typeToogle={watchPwd[1]?.length > 0}
        error={formState.errors?.confirmedPassword}
      ></Input>
      <button className="register__btn" onClick={handleSubmit(onSubmit)}>
        {mutation.isLoading ? "Reseting..." : "Reset Password"}
      </button>
      {mutation.isError && (
        <div className="register__error">
          {mutation.error?.response?.data?.message}
        </div>
      )}
    </LayoutWrapper>
  );
};

export default Reset;
